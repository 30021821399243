import fetchUtils from "../fetch-utils"
import utils from "./utils"
import { message } from "antd"

const getProps = ({ intl, sites, sources }) => ({
  entity: {
    name: "Prefix",
    id: "prefix",
    uri: "/prefixes",
    menuId: "context-prefix-index",
    translate: "models-prefixes",
    label: intl.formatMessage({
      id: "models-prefix",
    }),
  },
  formFields: [
    {
      name: "siteId",
      type: "text",
      placeholder: intl.formatMessage({ id: "models-site" }),
      dataSource: (sites.current || []).map(s => ({
        value: s.id,
        text: s.desc,
      })),
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: "page-contexts-prefixes-site-required-warning",
          }),
        },
      ],
    },
    {
      name: "sourceId",
      type: "text",
      placeholder: intl.formatMessage({ id: "models-source" }),
      dataSource: (sources.current || []).map(s => ({
        value: s.id,
        text: s.desc,
      })),
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: "page-contexts-prefixes-source-required-warning",
          }),
        },
      ],
    },
    {
      name: "desc",
      type: "text",
      placeholder: intl.formatMessage({ id: "description" }),
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: "page-contexts-prefixes-description-required-warning",
          }),
        },
      ],
    },
  ],
})

const getSites = async ({ sites, sources, intl, setState, prop }) => {
  try {
    sites.current = await fetchUtils.authFetchJson(
      `${process.env.GATSBY_CONF_API_URL}/sites`
    )
    if (sources.current) {
      await utils.refreshTable({ setState, intl, prop })
    }
  } catch (e) {
    message.error(intl.formatMessage({ id: "error-fetching-data" }))
  }
}

const getSources = async ({ sites, sources, intl, setState, prop }) => {
  try {
    sources.current = await fetchUtils.authFetchJson(
      `${process.env.GATSBY_CONF_API_URL}/sources`
    )
    if (sites.current) {
      await utils.refreshTable({ setState, intl, prop })
    }
  } catch (e) {
    message.error(intl.formatMessage({ id: "error-fetching-data" }))
  }
}

const prefixUtils = {
  getProps,
  getSites,
  getSources,
}

export default prefixUtils
