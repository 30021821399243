import React, { useEffect, useRef } from "react"
import { Col, Row } from "antd"
import { useIntl } from "gatsby-plugin-intl"

import Template from "../../layouts/base"
import withAuth from "../../components/withAuthHoc"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import WrappedFormNewContextItem from "../../components/contextNew"
import ListContextItem from "../../components/contextList"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import useStateObject from "../../hooks/useStateObject"
import utils from "../../utils/context/utils"
import prefixUtils from "../../utils/context/prefix-utils"

const PrefixPage = () => {
  const intl = useIntl()
  const prop = "prefixes"
  const [state, setState] = useStateObject({
    prefixes: null,
  })
  const sites = useRef(null)
  const sources = useRef(null)

  const hasPermission = checkPermissions([
    "prefix:write",
    "source:read",
    "site:read",
  ])

  useEffect(() => {
    ;(async () => {
      if (hasPermission) {
        await prefixUtils.getSites({ sites, sources, intl, setState, prop })
        await prefixUtils.getSources({ sites, sources, intl, setState, prop })
      }
    })()
  }, [])

  const { entity, formFields } = prefixUtils.getProps({ intl, sites, sources })

  return (
    <Template selected={["context", "context-site-index"]}>
      <CustomBreadcrumb
        crumbs={[
          `${intl.formatMessage({ id: "manage-of" })} ${intl.formatMessage({
            id: "models-prefixes",
          })}`,
        ]}
      />
      <Row>
        <Col className="intro">
          <h1>
            {intl.formatMessage({ id: "list-of" })}{" "}
            {intl.formatMessage({ id: "models-prefixes" })}
          </h1>
        </Col>
      </Row>
      <ListContextItem
        entity={entity}
        items={state.prefixes}
        onRowDelete={utils.onRowDelete({ intl, state, setState, prop })}
      />
      {hasPermission && (
        <WrappedFormNewContextItem
          entity={entity}
          formFields={formFields}
          onContextItemAdd={() => utils.refreshTable({ setState, intl, prop })}
          title={intl.formatMessage({ id: "create-new-prefix" })}
        />
      )}
    </Template>
  )
}

export default withAuth(PrefixPage)
